.menu-section {
  position: relative;
  padding: 15px;
}
.menu-block{
  position: relative;
  margin-bottom: 30px;
  .inner-box {
    position: relative;
    text-align: left;
    padding-top: 25px;
    .menu-tag {
      font-size: 12px;
      line-height: 22px;
      margin-bottom: 5px;
      letter-spacing: 1px;
      background: $color-primary;
      padding: 0 7px;
      font-weight: 400;
      color: $color-light;
      text-transform: uppercase;
      display: inline-block;
      text-align: left;
      position: absolute;
      top: 0;
      font-family: $heading-font;
    }
    .info {
      position: relative;
      margin-bottom: 5px;
      clear: both;
      display: block;
      list-style: none;
    }
    .post-title {
      position: relative;
      color: $color-dark;
      font-size: 20px;
      letter-spacing: 1px;
      -webkit-margin-after: 0;
      margin-block-end: 0;
      font-family:$decor-font;
      font-style: italic;
      font-weight: 500;
      display: table-cell;
      width: 1%;
      white-space: nowrap;
      padding-right: 5px;
      @include tablet{
        font-size: 16px;
      }
    }
    .line{
      height: 3px;
      border-bottom: 1px dashed rgba($color-primary,0.7);
      display: table-cell;
      width: 98%;
      z-index: 1;
      position: relative;
      bottom: 2px;
    }
    .price {
      position: relative;
      color: $color-primary;
      font-size: 36px;
      line-height: 36px;
      font-family:$decor-font;
      font-style: italic;
      font-weight: 500;
      margin-top: -6px;
      display: table-cell;
      width: 1%;
      padding-left: 7px;
      white-space: nowrap;
      @include tablet{
        font-size: 24px;
        line-height: 28px;
      }
    }
    .ingradien-text {
      position: relative;
      color: $color-font;
      font-size: 14px;
      padding-right: 80px;
      margin-bottom: 0;
      display: inline-block;
      width: 100%;
    }
  }
}