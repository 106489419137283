
// ----------------------------------------------------------------------
//mix in
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// placeholder text color
// example: @include placeholder-color(#333);
// ----------------------------------------------------------------------
@mixin placeholder-color($color) {
  &.placeholder {
    color: $color
  }
  &:-moz-placeholder {
    color: $color
  }
  &::-webkit-input-placeholder {
    color: $color
  }
  &:-ms-input-placeholder {
    color: $color
  }
}


// ----------------------------------------------------------------------
// Transitions
// example: @include single-transition(background, 1s, ease-in-out);
// ----------------------------------------------------------------------
@mixin single-transition($property, $duration, $timing-function) {
  -webkit-transition: $property $duration $timing-function;
  -moz-transition: $property $duration $timing-function;
  -o-transition: $property $duration $timing-function;
  transition: $property $duration $timing-function;
}

// ----------------------------------------------------------------------
// Linear Gradient angle
// example: @include linear-gradient( #cccccc, #333333);
// ----------------------------------------------------------------------
@mixin linear-gradient($colorStart, $colorStop){
  background: #{$colorStart};
  background: -webkit-linear-gradient(218deg,  #{$colorStart} 0%,#{$colorStop} 50%,#{$colorStart} 100%);
  background:    -moz-linear-gradient(218deg,  #{$colorStart} 0%,#{$colorStop} 50%,#{$colorStart} 100%);
  background:      -o-linear-gradient(218deg,  #{$colorStart} 0%,#{$colorStop} 50%,#{$colorStart} 100%);
  background:     -ms-linear-gradient(218deg,  #{$colorStart} 0%,#{$colorStop} 50%,#{$colorStart} 100%);
  background:         linear-gradient(218deg,  #{$colorStart} 0%,#{$colorStop} 50%,#{$colorStart} 100%);
}

// ----------------------------------------------------------------------
// Box Shadow
// example: @include box-shadow(1px, 2px, 2px, 2px, #000);
// ----------------------------------------------------------------------
@mixin box-shadow($hoff: false, $voff: false, $blur: false, $spread: false, $color: false){
  -webkit-box-shadow: $hoff $voff $blur $spread $color;
  -moz-box-shadow: $hoff $voff $blur $spread $color;
  box-shadow: $hoff $voff $blur $spread $color;
}


// ----------------------------------------------------------------------
// Media Queries
// Devices Media Queries
$special-phone: 667px;
$special-tablet: 990px;

// General Media Queries
$phone-width: 768px;
$tablet-width: 992px;
$medium-width: 1024px;
$notebook-width: 1280px;
$desktop-width: 1500px;
// ----------------------------------------------------------------------
@mixin landscape-phone {
  @media screen and (max-width: $special-phone) {
    @content;
  }
}

@mixin landscape-tablet {
  @media only screen and (max-width: $special-tablet){
    @content;
  }
}

@mixin phone {
  @media only screen and (max-width: $phone-width) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet-width) {
    @content;
  }
}

@mixin medium {
  @media only screen and (max-width: $medium-width) {
    @content;
  }
}

@mixin notebook {
  @media only screen and (max-width: $notebook-width) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: $desktop-width) {
    @content;
  }
}


//keyframes
@-webkit-keyframes btnIconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
  80% {
    border-width: 1px;
    -webkit-transform: scale(1.35);
    transform: scale(1.35)
  }
  100% {
    opacity: 0
  }
}

@keyframes btnIconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
  80% {
    border-width: 1px;
    -webkit-transform: scale(1.35);
    transform: scale(1.35)
  }
  100% {
    opacity: 0
  }
}

@-webkit-keyframes dotAnimations {
  from {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px)
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes dotAnimations {
  from {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px)
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@-webkit-keyframes slideFromBottom {
  from {
    -webkit-transform: translateY(105%);
    transform: translateY(105%)
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes slideFromBottom {
  from {
    -webkit-transform: translateY(105%);
    transform: translateY(105%)
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@-webkit-keyframes slideToTop {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  to {
    -webkit-transform: translateY(-105%);
    transform: translateY(-105%)
  }
}

@keyframes slideToTop {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  to {
    -webkit-transform: translateY(-105%);
    transform: translateY(-105%)
  }
}
