
/* 1. Reset tags
-------------------------------------------------------------- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,input,textarea,button,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, address, section, video, audio {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  font-weight: inherit;
  font-style: inherit;
  border: 0;
  outline: 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

a, a:visited, a:hover, a:focus {
  @include single-transition(all,ease-in-out,0.4s);
  text-decoration: none !important;
}
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-y: scroll !important;
  overflow-x: hidden;
  height: 100%;
}


html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  background:transparent;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
  display:block;
}
nav ul {
  list-style:none;
}
a {
  margin:0;
  padding:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
  text-decoration: none !important;
}

a:focus, button:focus{
  border:0;
  outline:0;
}

/* change colours to suit your needs */
ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}

/* change colours to suit your needs */
mark {
  background-color:#ff9;
  color:#000;
  font-style:italic;
  font-weight:bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom:1px dotted;
  cursor:help;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  outline: 0 none;
}


/* 2. Default tag's settings */

html {
  font-size: 16px; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block; }

h1, h2, h3, h4, h5, h6 {
  hyphens: auto; }

li > ol, li > ul, dl > dd {
  margin-bottom: 0; }

li > p {
  margin-bottom: 0; }

li > p + p {
  margin-top: 0.5em; }

ol, ul {
  padding-left: 1.5em; }

ol li, ul li {
  margin: 7px 0; }

/* Links */
a {
  text-decoration: none;
  background: transparent; }

a,
a:hover,
a:focus,
a:active {
  outline: 0; }

a img {
  border: none; }

a,
button,
input[type="button"],
input[type="submit"] {
  -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease; }

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  display: block; }

:active, :focus {
  outline: 0; }

a[href="javascript:void(0)"] {
  cursor: default; }

/* Tables */
table {
  border-collapse: collapse; }

table th + th, table th + td, table td + th, table td + td {
  border-left: 1px solid #ddd; }

table > p {
  margin: 0 !important; }

table th {
  padding: 1.4em 1em;
  letter-spacing: 1px; }

table td {
  padding: 1.4em 1em;
  font-size: 0.938em; }

table th {
  font-size: 1.125em;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  text-align: center; }

caption {
  font-weight: 500;
  text-align: center; }

/* Blockquotes*/
blockquote {
  color: $color-dark;
  font-size: 18px;
  padding: 0 28px;
  border-left: 2px solid $color-primary;
  font-style: italic;
  margin-bottom: 20px;
}
blockquote, q {
  quotes: "" ""; }

blockquote p {
  margin: 0;
  font-size: 1em;
  line-height: 1.62em;
  font-weight: 400; }

blockquote > cite, blockquote > p > cite {
  display: block;
  font-size: 1em;
  line-height: 1.6em;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  margin-top: 1.45em;
  letter-spacing: 2.2px; }

.blog_mode_post blockquote,
.blog_mode_page blockquote {
  margin: 2em 0 2em 3em; }

.blog_mode_post .comments_list blockquote,
.blog_mode_page .comments_list blockquote {
  margin: 1.5em 0; }

/* Other tags */
dd {
  margin-left: 1.5em; }

dt, b, strong {
  font-weight: bold; }

dfn, em, i {
  font-style: italic; }

pre, code, kbd, tt, var, samp {
  font-family: "Courier New", Courier, monospace;
  font-size: 1em;
  letter-spacing: 0; }

pre {
  overflow: auto;
  max-width: 100%; }

code {
  overflow: auto;
  max-width: 100%;
  padding: 0 1em;
  border: 1px solid #888;
  background-color: #f7f7f7;
  display: inline-block;
  vertical-align: middle;
  word-wrap: break-word; }

pre > code {
  display: block;
  vertical-align: top;
  padding: 1em; }

abbr, acronym {
  border-bottom: 1px dotted;
  cursor: help; }

mark, ins {
  background-color: transparent;
  text-decoration: none; }

sup,
sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  bottom: 1ex; }

sub {
  top: .5ex; }

small {
  font-size: 80%; }

big {
  font-size: 120%; }

[hidden], template {
  display: none; }

hr {
  height: 0;
  border: none;
  border-top: 1px solid #eee;
  margin: 6.7857em 0;
  -webkit-box-sizing: content-box;
  -ms-box-sizing: content-box;
  box-sizing: content-box; }

/* Images */
img {
  max-width: 100%;
  /*	width: auto; */
  /* Only height: auto; not both! */
  height: auto;
  vertical-align: top; }

figure,
.wp-caption,
.wp-caption-overlay .wp-caption {
  border: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  max-width: 100%; }

figure figcaption,
.wp-caption .wp-caption-text,
.wp-caption .wp-caption-dd,
.wp-caption-overlay .wp-caption .wp-caption-text,
.wp-caption-overlay .wp-caption .wp-caption-dd {
  position: absolute;
  z-index: 1;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 1.125em;
  line-height: 1.125em;
  font-weight: 500;
  font-style: normal;
  -webkit-transition: margin-bottom 0.3s ease;
  -ms-transition: margin-bottom 0.3s ease;
  transition: margin-bottom 0.3s ease;
  margin-bottom: 0;
  text-align: center;
  padding: 15px 10px;
  overflow: hidden;
  text-transform: capitalize; }

figure:hover figcaption,
.wp-caption:hover .wp-caption-text,
.wp-caption:hover .wp-caption-dd,
.wp-caption-overlay .wp-caption:hover .wp-caption-text,
.wp-caption-overlay .wp-caption:hover .wp-caption-dd {
  margin-bottom: 0; }

figure figcaption:hover,
.wp-caption .wp-caption-text:hover,
.wp-caption .wp-caption-dd:hover,
.wp-caption-overlay .wp-caption .wp-caption-text:hover,
.wp-caption-overlay .wp-caption .wp-caption-dd:hover {
  margin-bottom: 0; }

svg:not(:root) {
  overflow: hidden; }

/* Audio and Video */
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

iframe, video, embed {
  max-width: 100%;
  min-height: 100px;
  vertical-align: top; }



