//call to action

.call-to-action-one{
  display: flex;
  align-items: center;
  padding: 50px 25px;
  border: solid 1px #f1f1f1;
  border-radius: 0;
  position: relative;
  .call-data{
    text-align: left;
    width: 70%;
    display: flex;
    align-items: center;
    i{
      font-size: 64px;
      display: inline-block;
      color: $color-dark;
    }

    h3{
      margin-bottom: 0;
    }
    p{
      font-size: 1em;
    }
    .call-text{
      display: inline-block;
      margin-left: 20px;
    }
  }
  .call-btn{
    width: 30%;
    text-align: right;
  }
}

.call-to-action-two{
  display: block;
  padding:15px;
  position: relative;
  text-align: center;

  .call-data{
    max-width: 800px;
    margin: 0 auto;
    .icon{
      font-size: 64px;
      width: 70px;
      height: 70px;
      color: $color-black;
      display: inline-block;
      margin-bottom: 25px;
      line-height: 1;
      svg , svg *{
        fill: $color-primary;
      }
    }
    .number{
      font-size: 120px;
      color: $color-primary;
      display: inline-block;
      margin-bottom: 35px;
      line-height: 1;
      font-family:$decor-font;
      font-style: italic;
      @include tablet{
        font-size: 80px;
        margin-bottom: 25px;
      }
    }
    .title{
      margin: 0;
      font-family: $decor-font;
      font-style: italic;
      font-weight: 500;
      margin-bottom: 20px;
    }
    p{
      font-size: 18px;
      margin-bottom: 20px;
      line-height: 1.8;
      @include tablet{
        font-size: 16px;
        line-height: 1.5em;
      }
    }
    .call-text{
      display: inline-block;
    }
  }
  .call-btn{
    margin-top: 15px;
  }
}

@media screen and (max-width:768px) {
  .call-to-action-one{
    display: block;
    .call-data{
      width: 100%;
      display: block;
      text-align: center;
    }
    .call-btn{
      width: 100%;
      text-align: center;
    }
  }
}


.contact-img-box{
  background-color: #ffffff;
  z-index: 99;
  position: relative;
  border-radius: 0;
  overflow: hidden;
  box-shadow: 5px 5px 15px 0 rgba(17, 17, 17, 0.06);
  .contact-box-data{
    padding: 25px;
    @include tablet{
      padding:25px 15px;
    }
  }
  .contact-img{
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

}


.map-horizontal{
  width: 100%;
  min-height: 400px;
}

//app download data

.download-app-data{
  position: relative;
  .title{
    font-family: $decor-font;
    font-style: italic;
    font-weight: 500;
    margin: 0 0 15px;
  }
  .text{
    font-size: 18px;
    line-height: 1.5;
  }
  .btn{
    margin-right: 15px;
    @include tablet{
      margin-right: 0;
      width: 100%;
      text-align: left;
    }
  }
  .portype{
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    min-height: 400px;
    @include tablet{
      position: relative;
    }
  }
}