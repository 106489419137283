.team-item{
  position: relative;
  border-radius: 0;
  box-shadow: 0 8px 24px rgba(0, 0, 0, .1);
  margin: 0 0 30px;
  @include single-transition(all,ease-in-out,0.4s);
  .team-img{
    width: 100%;
    height: 350px;
    border-radius: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .team-wrap{
    background: rgba($color-black, .6);
    position: absolute;
    border-radius:0;
    top: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    opacity: 0;
    @include single-transition(all,ease-in-out,0.4s);
    .team-content{
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 20px;
      transform: translateY(-10px);
      @include single-transition(all,ease-in-out,0.4s);
      .team-name{
        font-size: 16px;
        margin-bottom: 2px;
        color: $color-light;
        line-height: 1.2;
        font-weight: 500;
        text-transform: capitalize;
        font-family: $body-font;
      }
      .team-role{
        font-size: 14px;
        color: $color-gray;
        font-weight: 400;
      }

    }
    .team-content-social{
      text-align: center;
      list-style: none;
      padding: 20px;
      margin: 0;
      transform: translateY(-10px);
      position: absolute;
      right: 0;
      @include single-transition(all,ease-in-out,0.4s);
      ul{
        padding: 0;
        margin: 0;
        li{
          margin: 0;
          margin-bottom: 5px;
          a{
            color: $color-light;
            &:hover{
              color: $color-primary;
            }
          }
        }
      }
    }
  }

  &:hover{
    transform: translateY(-3px);
    @include single-transition(all,ease-in-out,0.4s);
    .team-wrap{
      opacity: 1;
      @include single-transition(all,ease-in-out,0.4s);
    }
    .team-content,.team-content-social{
      transform: translateY(0);
      @include single-transition(all,ease-in-out,0.4s);
    }
  }
}
.team-item-two{
  position: relative;
  border-radius: 0;
  overflow: hidden;
  margin: 0 0 30px;
  @include single-transition(all,ease-in-out,0.4s);
  box-shadow: 5px 5px 1px rgba($color-primary,0.4);
  border: solid 1px $color-gray;
  .team-img{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 400px;
    border-radius: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &:after{
      content: "";
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      border-radius: 0;
      top: 0;
      overflow: hidden;
      height: 100%;
      width: 100%;
      opacity: 0;
      @include single-transition(all,ease-in-out,0.4s);

    }
  }
  .team-wrap{
    height: 100%;
    width: 100%;
    transition: all ease-in-out 0.4s;
    position: relative;
    color:$color-dark;
    background-color: $color-light;
    @include single-transition(all,ease-in-out,0.4s);
    .team-content{
      transition: all ease-in-out 0.4s;
      position: relative;
      display: inline-block;
      width: 100%;
      text-align: center;
      padding: 20px;
      @include single-transition(all,ease-in-out,0.4s);
      .team-name{
        margin-bottom: 10px;
        margin-top: 0;
        color: $color-dark;
        line-height: 1.2;
        font-weight: 600;
        text-transform: capitalize;
      }
      .team-role{
        color: $color-primary;
        text-transform: uppercase;
        font-size: 18px;
        font-family: $decor-font;
        font-style: italic;
        font-weight: 500;
      }

    }
    .team-content-social{
      text-align: center;
      list-style: none;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 0;
      opacity: 0;
      top: -360px;
      transform: translateY(-10px);
      @include single-transition(all,ease-in-out,0.4s);
      ul{
        padding: 10px;
        margin: 0;
        list-style: none;
        li{
          margin: 10px 0;
          a{
            color: $color-light;
            border-radius: 0;
            width: 30px;
            height: 30px;
            display: block;
            padding: 6px;
            &:hover{
              color: $color-primary;
            }
          }
        }
      }
    }
  }

  &:hover{
    box-shadow: 0px 0px 0px rgba($color-primary,0.4);
    @include single-transition(all,ease-in-out,0.4s);

    .team-img:after,.team-content-social{
      transform: translateY(0);
      opacity: 1;
      @include single-transition(all,ease-in-out,0.4s);
    }
  }
}