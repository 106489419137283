.heading-line{
  height: 6px;
  display: block;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  &:before{
    content: "";
    border-top: solid 2px;
    height: 0;
    width: 30px;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    margin: auto;
  }
  &:after{
    content: "";
    border-top: solid 2px;
    height: 0;
    width: 20px;
    position: absolute;
    right: 0;
    left: 0;
    top: 5px;
    margin: auto;
  }
}

.text-left .heading-line{
  &:before{
    right: auto;
  }
  &:after{
    right: auto;
  }
}
.text-right .heading-line{
  &:before{
    left: auto;
  }
  &:after{
    left: auto;
  }
}

.text-left .icon{
  margin-left: 0;
  margin-right: auto;
}
.text-right .icon{
  margin-right: 0;
  margin-left: auto;
}

.section-title {
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  .icon {
    font-size: 46px;
    color: $color-primary;
    display: block;
    max-width: 70px;
    margin: 0 auto;
    margin-bottom: 15px;
    svg{
      fill:$color-dark;
    }
  }
  .small-title {
    text-transform: uppercase;
    position: relative;
    font-family: $decor-font;
    font-weight: 500;
    font-size: 16px;
    font-style: italic;
    line-height: 27px;
    color: $color-primary;
    margin: 0;
    margin-bottom: 7px;
    letter-spacing: 2px;
  }
  .title {
    margin-top:0;
    margin-bottom: 20px;
  }
  p{
    font-size: 16px;
  }
}

.section-title-two{
  position: relative;
  margin-top: 10px;
  .small-title {
    text-transform: uppercase;
    color: $color-primary;
    position: relative;
    margin-bottom: 0;
    margin-top: -5px;
    font-family: $body-font;
  }
  .title {
    margin-top:0;
    margin-bottom:53px;
    color: $color-dark;
    text-transform: uppercase;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom:-40px;
      background-image: url(../imgs/pattern.png);
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center center;
      overflow: hidden;
      border: 0 !important;
      width: 80px;
      height: 30px;
    }
    &.color-light:before{
      background-image: url(../imgs/pattern-light.png);
    }
    &.color-dark:before{
      background-image: url(../imgs/pattern-dark.png);
    }
  }
  &.text-right{
    .title:before{
      left: auto;
      right: 0;
    }
  }
  &.text-center{
    .title:before{
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
  p{
    font-size: 16px;
  }
}
