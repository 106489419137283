.button, input[type='button'], input[type='reset'], .btn{
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 10px 0;
  line-height: 1;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 400;
  text-transform: uppercase;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 40px;
  padding-right: 40px;
  vertical-align: middle;
  background: transparent;
  font-family: $heading-font;
  color: $color-dark;
  text-align: center;
  border-radius: 0;
  border: 2px solid $color-dark;
  box-shadow: 0 0 0 rgba(0,0,0,.05);
  @include single-transition(all , 0.4s , ease-in-out);
  &:hover{
    opacity: 1;
    color: $color-light;
    box-shadow:0 4px 4px rgba(0,0,0,.05);
    background-color: $color-dark;
    @include single-transition(all , 0.4s , ease-in-out);
  }
  &:focus{
    opacity: 1;
    box-shadow:0 4px 4px rgba(0,0,0,.05);
    color: $color-light;
    background-color: $color-dark;
    @include single-transition(all , 0.4s , ease-in-out);
  }
}

.btn-rounded{
  border-radius: 150px;
}
.btn-rounded{
  border-radius: 150px;
}
.btn-square{
  border-radius: 0;
}
.btn-icon-left{
  .icon-i{
    float: right;
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: -8px;
    margin-bottom: -7px;
    position: relative;
    margin-right: -5px;
    i{
      position: absolute;
      font-size: 24px;
      top: 3px;
      left: 0;
      @include single-transition(all , 0.4s , ease-in-out);
    }
  }
}
.btn-icon-right{
  .icon-i{
    float: right;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-top: -8px;
    margin-bottom: -7px;
    position: relative;
    margin-right: -5px;
    i{
      position: absolute;
      right: 0;
      font-size: 24px;
      top: 3px;
      @include single-transition(all , 0.4s , ease-in-out);
      &:hover,&:focus{
        @include single-transition(all , 0.4s , ease-in-out);
      }
    }
  }
}
.circle-icon-btn{
  width: 90px;
  height: 90px;
  padding: 30px;
  border-radius: 150px;
  font-size: 28px;
  position: relative;
  overflow: visible;
  &:before{
    position: absolute;
    content: "";
    border: solid 1px $color-primary;
    border-radius: 150px;
    width: 90px;
    height: 90px;
    left: -2px;
    top: -2px;
    animation: btnIconRipple 2s cubic-bezier(.23,1,.32,1) both infinite;
  }

}
.btn-small{
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 30px;
  padding-right: 30px;
}
.btn-big{
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 50px;
  padding-right: 50px;
}

.btn-primary{
  border: 2px solid $color-primary !important;
  background-color: $color-primary !important;
  color: $color-light !important;
  box-shadow: 5px 5px 0 0 rgba($color-primary,0.3);
  i{
    color: $color-light !important;
  }
  &:hover,&:focus{
    border: 2px solid $color-dark !important;
    background-color: $color-dark !important;
    color: $color-light !important;
    box-shadow: 0 0 0 0 rgba($color-primary,0.00);
    i{
      color: $color-light !important;
    }
  }
}
.btn-primary-outline{
  border: 2px solid $color-primary !important;
  background-color: transparent !important;
  color: $color-primary !important;
  box-shadow: 5px 5px 0 0 rgba($color-primary,0.3);

  i{
    color: $color-primary !important;
  }
  &:hover,&:focus{
    border: 2px solid $color-primary !important;
    background-color: $color-primary !important;
    color: $color-light !important;
    box-shadow: 0 0 0 0 rgba($color-primary,0.00);
    i{
      color: $color-light !important;
    }
  }
}


.btn-dark{
  border: 2px solid $color-dark !important;
  background-color: $color-dark !important;
  color: $color-light !important;
  box-shadow: 5px 5px 0 0 rgba($color-dark,0.3);
  i{
    color: $color-light !important;
  }
  &:hover,&:focus{
    border: 2px solid $color-black !important;
    background-color: $color-black !important;
    box-shadow: 0 0 0 0 rgba($color-primary,0.00);
    color: $color-light !important;
    i{
      color: $color-light !important;
    }
  }
}
.btn-dark-outline{
  border: 2px solid $color-dark !important;
  background-color: transparent !important;
  color: $color-dark !important;
  box-shadow: 5px 5px 0 0 rgba($color-dark,0.08);
  i{
    color: $color-dark !important;
  }
  &:hover,&:focus{
    border: 2px solid $color-dark !important;
    background-color: $color-dark !important;
    color: $color-light !important;
    box-shadow: 0 0 0 0 rgba($color-primary,0.00);
    i{
      color: $color-light !important;
    }
  }
}

.btn-light{
  border: 2px solid $color-light !important;
  background-color: $color-light !important;
  color: $color-primary !important;
  box-shadow: 5px 5px 0 0 rgba($color-light,0.08);
  i{
    color: $color-primary !important;
  }
  &:hover,&:focus{
    border: 2px solid $color-light !important;
    background-color: $color-light !important;
    color: $color-primary !important;
    box-shadow: 0 0 0 0 rgba($color-primary,0.00);
    i{
      color: $color-primary !important;
    }
  }
}
.btn-light-outline{
  border: 2px solid $color-light !important;
  background-color: transparent !important;
  color: $color-light !important;
  box-shadow: 5px 5px 0 0 rgba($color-light,0.08);

  i{
    color: $color-light !important;
  }
  &:hover,&:focus{
    border: 2px solid $color-light !important;
    background-color: $color-light !important;
    color: $color-primary !important;
    box-shadow: 0 0 0 0 rgba($color-primary,0.00);
    i{
      color: $color-primary !important;
    }
  }
}