.testimonial-box-one{
  position: relative;
  padding: 60px 75px;
  margin: 10px 0;
  background: $color-light;
  border-radius: 0;
  @include single-transition(all,ease-in-out,0.4s);

  .testimonial-img{
    float: left;
    position: relative;
    z-index: 5;
    margin-bottom: 15px;
    width: 60px !important;
    height: 60px;
    border-radius: 0;
    border: solid 3px $color-primary;
    padding: 3px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .t-info{
    position: relative;
    left: 12px;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 4px;
    .t-name{
      margin: 0;
      line-height: 1.3;
    }
    .t-job{
      margin: 0;
      font-size: 13px;
      line-height: 1.2;
      color: $color-primary;
      margin-top: 7px;
      font-family: $decor-font;
      font-style: italic;
      font-weight: 500;
    }
  }
  .t-content{
    font-size: 16px;
    line-height: 1.9;
    font-weight: 400;
    clear: both;
    position: relative;
    p{
      font-size: 17px;
      line-height: 1.6;
      clear: both;
      text-align: start;
      margin-bottom: 0;
      font-family: $decor-font;
      font-style: italic;
      font-weight: 500;
      color: $color-dark;
    }
    .t-star-rating{
       ul{
        list-style:none;
        padding:0;
        li {
          display:inline-block;
          cursor: pointer;
          span {
            font-size: 12px;
            color: $color-gray-dark;
          }
          &.active span{
            color:$color-orange;
          }
        }
      }
    }
  }
  @include tablet{
    padding: 25px 20px;
  }
}
.testimonial-box-two{
  padding: 40px;
  margin: 10px;
  border-radius: 0px;position: relative;
  @include single-transition(all,ease-in-out,0.4s);
  .icon{
    font-size: 14em;
    color: $color-primary;
    margin-bottom: 15px;
    position: absolute;
    opacity: 0.1;
    top: 10px;
    line-height: 1;
  }
  .t-img{
    float: left;
    position: relative;
    z-index: 5;
    margin-bottom: 20px;
    width: 65px!important;
    height: 65px;
    border-radius:0%;
  }
  .t-info{
    position: relative;
    top: 15px;
    left: 12px;
    font-size: 16px;
    text-align: left;
    .t-name{
      margin: 0;
      line-height: 1.3;
    }
    .t-job{
      margin: 0;
      line-height: 1.2;
      color: $color-font;
      font-weight: 500;
      font-family: $body-font;
    }
  }
  .t-content {
    font-size: 18px;
    line-height: 1.9;
    font-weight: 600;
    clear: both;
    margin-bottom: 15px;
    position: relative;
    p {
      font-size: 18px;
      line-height: 1.4;
      color: $color-dark;
      clear: both;
      text-align: start;
      font-family: $decor-font;
      font-style: italic;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

}

.testimonial-box-three{
  position: relative;
  margin: 15px 35px;
  padding: 5px;
  .icon{
    color: $color-primary;
    font-size: 64px;
    line-height: 1;
  }
  .t-content p{
    font-size: 18px;
    line-height: 1.8;
    margin: 25px 0;
    color: $color-dark;
    clear: both;
    text-align: center;
    font-family: $decor-font;
    font-style: italic;
    font-weight: 500;
  }
  .t-info{
    position: relative;
    .t-name{
      margin: 0;
      line-height: 1.3;
    }
    .t-job{
      font-size: 14px;
      margin: 0 4px;
      line-height: 1;
      color: $color-font;
      font-weight: 500;
      font-family: $decor-font;
    }
  }
  @include tablet{
    margin: 10px 0;
  }
}



.testimonial-one.owl-carousel .owl-nav,.testimonial-two.owl-carousel .owl-nav{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
  button.owl-prev,button.owl-next{
    color: $color-primary;
    outline: 0 !important;
    border: solid 2px !important;
    padding: 11px 7px !important;
    box-shadow: 0 0 0 !important;
    width: 50px;
    height:50px;
    font-size: 20px;
    border-radius: 0;
    background-color: transparent;
    border-color: $color-primary !important;
    &:hover{
      opacity: 0.9;
    }
  }
  .owl-prev{
    position: absolute;
    top: calc( 50% - 55px);
    left: -55px;
    @include tablet{
      left: 0;
      z-index: 8;
    }
  }
  .owl-next{
    position: absolute;
    top: calc( 50% - 55px);
    right: -55px;
    @include tablet{
      right: 0;
      z-index: 8;
    }
  }
}
.owl-carousel .owl-stage-outer{
  z-index: 5;
}

