.icon-box-three{
  text-align: center;
  padding:15px;
  cursor: pointer;
  position: relative;
  transform: translateY(0);
  @include single-transition(all,0.3s, ease-in-out);
  .icon{
    font-size: 64px;
    padding: 0;
    text-align: center;
    color: $color-dark;
    display: inline-block;
    margin-bottom: 20px;
    max-width: 65px;
    @include single-transition(all,0.3s, ease-in-out);
    svg{
      width: 65px;
      fill: $color-primary;
      *{
        fill: $color-primary;
      }
    }
  }
  .title{
    margin-bottom: 15px;
    margin-top: 0;
    text-transform: uppercase;
    color: $color-dark;
  }
  p{
    margin: 0;
  }
  .link{
    color: $color-primary;
    text-transform: capitalize;
    margin-top: 10px;
    font-family: $decor-font;
    font-style: italic;
    font-weight: 500;
    display: inline-block;
    @include single-transition(all , ease-in-out, 0.4s);
    &:hover{
      @include single-transition(all , ease-in-out, 0.4s);
      color: $color-dark;
    }
  }
  &:hover{
    transform: translateY(-10px);
    @include single-transition(all,0.3s, ease-in-out);
    .icon{
      color: $color-primary;
      @include single-transition(all,0.3s, ease-in-out);
    }
  }

}





.icon-box-two{
  border-radius: 0;
  background-color: #ffffff;
  border: solid 1px $color-gray;
  padding:60px 40px;
  margin: 15px 0;
  cursor: pointer;
  @include box-shadow(0 ,0 ,0 ,0,rgba($color-black,0.04));
  @include single-transition(all , ease-in-out, 0.4s);
  .icon{
    font-size: 64px;
    padding: 0;
    text-align: center;
    height: 65px;
    max-width: 65px;
    color: $color-dark;
    display: inline-block;
    margin-bottom: 20px;
    @include single-transition(all , ease-in-out, 0.3s);
    svg{
      fill: $color-dark;
      @include single-transition(all , ease-in-out, 0.3s);
      width: 65px;
      *{
        fill: $color-dark;
        @include single-transition(all , ease-in-out, 0.3s);
      }
    }
  }
  .title{
    margin-bottom: 15px;
    margin-top: 0;
    color: $color-dark;
  }
  p{
    margin: 0;
  }
  .link{
    color: $color-primary;
    text-transform: capitalize;
    margin-top: 10px;
    font-family: $decor-font;
    font-style: italic;
    font-weight: 500;
    display: inline-block;
    @include single-transition(all , ease-in-out, 0.4s);
    &:hover{
      @include single-transition(all , ease-in-out, 0.4s);
      color: $color-dark;
    }
  }

  &:hover{
    @include box-shadow(0 ,0 ,15px ,0,rgba($color-black,0.04));
    @include single-transition(all , ease-in-out, 0.4s);
    .icon{
      color: $color-primary;
      @include single-transition(all , ease-in-out, 0.3s);
      svg,svg *{
        fill: $color-primary;
        @include single-transition(all , ease-in-out, 0.3s);
      }
    }
  }
}
.icon-box-four {
  padding: 0;
  margin: 0 0 50px;
  cursor: pointer;
  display: inline-block;
  border-radius: 0;
  @include single-transition(all , ease-in-out, 0.4s);
  .icon {
    float: left;
    font-size: 64px;
    text-align: center;
    color: $color-dark;
    display: inline-block;
    margin-bottom: 20px;
    padding: 0;
    max-width: 65px;
    margin-top: -5px;
    @include single-transition(all , ease-in-out, 0.4s);
    svg{
      width: 65px;
      fill: $color-dark;
      @include single-transition(all , ease-in-out, 0.3s);
      *{
        fill: $color-dark;
        @include single-transition(all , ease-in-out, 0.3s);
      }
    }
  }
  .content {
    text-align: left;
    margin-left: 85px;
    .title {
      margin-bottom: 5px;
      text-transform: uppercase;
      margin-top: 0;
      color: $color-dark;
    }
    p{
      margin: 0;
    }
    .link{
      color: $color-primary;
      text-transform: capitalize;
      margin-top: 10px;
      font-weight: 500;
      display: inline-block;
    }
  }
  &:hover{
    i {
      color: $color-primary;
      @include single-transition(all , ease-in-out, 0.4s);
    }
    .icon{
      svg{
        width: 65px;
        fill: $color-primary;
        @include single-transition(all , ease-in-out, 0.3s);
        *{
          fill: $color-primary;
          @include single-transition(all , ease-in-out, 0.3s);
        }
      }
    }
  }
  &.text-right{
    .icon {
      float: right;
    }
    .content {
      margin-right: 85px;
      margin-left: 0;
      text-align: right;
    }
  }
}

.icon-box-five {
  padding: 20px 15px;
  margin: 15px 0;
  cursor: pointer;
  display: flex;
  border-radius: 0;
  @include single-transition(all , ease-in-out, 0.4s);
  i {
    float: left;
    font-size: 64px;
    padding: 0 20px;
    text-align: center;
    color: $color-primary;
    @include single-transition(all , ease-in-out, 0.4s);
  }
  .content {
    text-align: left;
    margin-left: 10px;
    h5 {
      color: $color-black;
      margin-bottom: 10px;
    }
  }
  &:hover{
    i {
      color: $color-black;
      @include single-transition(all , ease-in-out, 0.4s);
    }
  }
}


.img-box{
  margin: 15px auto;
  position: relative;
  .badge-box{
    background-color: $color-primary;
    color: $color-light;
    position: absolute;
    left: 15px;
    top: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    overflow: hidden;
    border-radius: 0;
    padding: 3px 20px;
  }
  .img-box-img{
    background-position: center center;
    background-size: cover;
    width: 100%;
    max-width: 420px;
    height: 370px;
    border-radius: 0;
    margin-bottom: 15px;
  }
  .img-box-info{
    h4{
      font-size: 18px;
      font-weight: 600;
      clear: both;
      line-height: 28px;
      margin-top: 6px;
      margin-bottom: 0;

    }
    p{
      margin: 0 0 10px;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0;
      width: 100%;
    }
  }
}

.restaurant-box{
  padding: 60px 50px;
  background-color: #ffffff;
  position: relative;
  display: block;
  border-radius: 0;
  margin: 15px auto;
  .box-img img{
    width: 60px;
    height: 60px;
    border-radius: 0;
    border: solid 2px $color-primary;
    padding: 3px;
    margin-bottom: 20px;
  }
  .content{
    display: inline-block;
    p{
      font-size: 14px;
    }
    .box-title{
      color: $color-dark;
      margin-bottom: 0;
    }
    .restaurant-rating{
      &:before{
        content: "\f005";
        color: $color-orange;
        font-size: 12px;
        letter-spacing: 5px;
        font-family: 'Font Awesome 5 Free';
        font-weight: 400;
      }
      &.star2:before {
        content: "\f005\f005";
      }
      &.star3:before {
        content: "\f005\f005\f005";
      }
      &.star4:before {
        content: "\f005\f005\f005\f005";
      }
      &.star5:before {
        content: "\f005\f005\f005\f005\f005";
      }

    }
  }
}