.form-control {
  height: 50px;
  margin: 15px 0;
  border-radius: 0;
  font-size: 14px;
  text-transform: capitalize;
  padding: 15px;
  outline: 0 !important;
  box-shadow: 0 0 0 !important;
  background-color: $color-gray;
  border: solid 1px #e9ecef;
  color: $color-primary;
  font-family: $decor-font;
  FONT-STYLE: italic;
  &:focus{
    border: solid 1px $color-primary;
    background-color: $color-light;
    outline: 0 !important;
  }
}
textarea.form-control{
  height: auto;
  min-height: 200px;
}
.nice-select.form-control {
  width: 100%;
  padding: 4px 14px;
  min-height: 50px;
  background-color: $color-gray;
  border: solid 1px #e8e8e8;
  color: $color-primary;
  font-family: $decor-font;
  FONT-STYLE: italic;
}
.nice-select.open .list{
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
}
.nice-select .list{
  width: 100%;
  max-height: 300px;
  color: $color-primary;
  font-family: $decor-font;
  FONT-STYLE: italic;


}