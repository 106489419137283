.tab-content{
  padding: 50px 0 0;
}
.icon-tabs {
  border-bottom: 0;
  margin-bottom: 25px;
  text-align: center;
  font-size: 0;
  li {
    width: 25%;
    display: inline-block;
    float: none;
    @include tablet{
      margin-bottom: 20px;
    }
    a {
      text-transform: uppercase;
      font-size: 21px;
      border-radius: 0;
      font-weight: 500;
      color: $color-dark;
      font-family: $heading-font;
      border: 0;
      margin-right: 0;
      @include tablet{
        font-size: 16px;
      }
      @include single-transition(all,ease-in-out,0.4s);
      i {
        display: block;
        font-size: 46px;
        margin-bottom: 20px;
        @include tablet{
          margin-bottom: 10px;
        }
      }
      img{
        width: 65px;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
        @include tablet{
          margin-bottom: 10px;
        }
      }
      &:hover,&:focus{
        background-color: transparent;
        border-color: transparent;
      }
      &.active,&:hover,&:focus{
        opacity: 1;
        border: 0;
        background-color: transparent;
        color: $color-primary;
        cursor: pointer;
        outline: none;
      }
    }
  }
}
.icon-tabs.cols-6 > li {
  width: 16.66666667%;
}
.icon-tabs.cols-5 > li {
  width: 20%;
}
.icon-tabs.cols-4 > li {
  width: 25%;
}
.icon-tabs.cols-3 > li {
  width: 33.33333333%;
}
.icon-tabs.cols-2 > li {
  width: 50%;
}
@media only screen and (max-width: 991px) {
  .icon-tabs.cols-6 > li,
  .icon-tabs.cols-5 > li,
  .icon-tabs.cols-4 > li,
  .icon-tabs.cols-3 > li {
    width: 50%;
  }
}