.sidebar .widget{
  margin: 0 0 30px;
  padding: 25px 30px;
  border-radius: 0;
  background: $color-light;
  .widget-title {
    font-size: 20px;
    letter-spacing: .5px;
    line-height: 1.22727em;
    padding: 0;
    position: relative;
    margin: 0 0 20px;
    color: $color-dark;
  }
  .widget-content{
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li{
        margin: 0 0 10px;
        overflow: hidden;
        a{
          color: $color-primary;
          font-weight: 400;
          margin-right: 10px;
          font-family: $decor-font;
          font-style: italic;
          &:hover{
            color: $color-dark;
          }
        }
      }
    }
  }

  .tag-cloud-link {
    padding: 5px 12px;
    background-color: $color-gray;
    margin: 7px 5px;
    display: inline-block;
    text-transform: capitalize;
    font-family:$decor-font;
    font-size: 13px;
    font-style: italic;
    @include single-transition(all,ease-in-out,0.4s);
    box-shadow:  5px 5px 0 0 rgba($color-gray-dark,0.2);
    &:hover{
      background-color: $color-primary;
      color: $color-light;
      @include single-transition(all,ease-in-out,0.4s);
      box-shadow:  0 0 0 0 rgba($color-gray-dark,0.2);
    }
  }

  &.social_media_widget{
    ul li {
      display: inline-block;
      margin: 0 !important;
      a{
        display: block;
        font-size: 18px;
        background-color: $color-gray;
        padding: 7px;
        width: 40px;
        height: 40px;
        text-align: center;
        margin: 2px !important;
        color: $color-primary;
        @include single-transition(all,ease-in-out,0.4s);
        box-shadow: 5px 5px 0 0 rgba($color-gray-dark,0.2);
        &:hover{
          background-color: $color-primary;
          color: $color-light;
          @include single-transition(all,ease-in-out,0.4s);
          box-shadow:  0 0 0 0 rgba($color-gray-dark,0.2);

        }
      }
    }

  }

  &.widget_search {
    padding: 0;
    position: relative;
    label{
      width: 100%;
    }
    .search-field {
      height: 50px;
      margin: 5px 0;
      border-radius: 0;
      font-size: 14px;
      text-transform: capitalize;
      padding: 15px 40px 15px 15px;
      outline: 0 !important;
      box-shadow: 0 0 0 !important;
      color: $color-primary;
      font-family: $decor-font;
      FONT-STYLE: italic;
      width: 100%;
      display: block;
    }
    .search-submit{
      display: none;
    }
    i{
      position: absolute;
      right: 15px;
      top: 22px;
      color: $color-dark;
    }
  }

  &.widget_categories{
    ul li{
      position: relative;
      span{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
        border: 1px solid $color-primary;
        width: 25px;
        height: 25px;
        line-height: 20px;
        text-align: center;
        color: $color-dark;
        font-family: $decor-font;
        font-style: italic;
      }
    }
  }
  .insta-photo {
    padding: 0;
    width: 75px;
    height: 75px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 4px 2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba($color-dark,0.8);
      @include single-transition(all,ease-in-out,0.4s);

    }
    a {
      display: flex;
      color: $color-light;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      justify-content: center;
      align-items: center;
      @include single-transition(all,ease-in-out,0.4s);
    }
    &:hover{
      &:before{
        background-color: rgba($color-dark,0.4);
        @include single-transition(all,ease-in-out,0.4s);

      }
      a{
      color: $color-primary;
      @include single-transition(all,ease-in-out,0.4s);
      }
    }
  }

  .news-img-list{
    li{
      overflow: hidden;
      font-size: 12px;
      line-height: 22px;
      margin: 0 0 15px !important;
      .widget-news-item{
        display: flex;
        a{
          display: inline-block;
          margin: 0;
        }
        .news-img{
          width: 60px;
          height: 60px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          border: solid 2px $color-primary;
        }
        .content{
          display: inline-block;
          width: calc(100% - 65px);
          padding-left: 10px;
          .title a{
            font-size: 16px;
            line-height: 21px;
            font-family: $heading-font;
            color: $color-dark;
            font-weight: 500;
            text-transform: uppercase;
            font-style: normal;
            margin: 0;
          }
          .meta{
              margin-top: 4px;
            .author{
              font-size: 12px;
              line-height: 22px;
              font-weight: 400;
              font-family: $decor-font;
              font-style: italic;
              display: inline-block;
              margin: 0 !important;
            }
            .date{
              font-size: 12px;
              line-height: 22px;
              font-weight: 400;
              font-family: $decor-font;
              font-style: italic;
              display: inline-block;
              margin: 0 !important;
              margin-left: 5px !important;
            }
          }
        }
      }
    }
  }
  .news-list{
    li{
      overflow: hidden;
      font-size: 12px;
      line-height: 22px;
      margin: 0 0 15px !important;
      .widget-news-item{
        display: flex;
        a{
          display: inline-block;
          margin: 0;
        }
        .content{
          display: inline-block;
          width: 100%;
          .title a{
            font-size: 16px;
            line-height: 21px;
            font-family: $heading-font;
            color: $color-dark;
            font-weight: 500;
            text-transform: uppercase;
            font-style: normal;
            margin: 0;
          }
          .meta{
            margin-top: 4px;
            .author{
              font-size: 12px;
              line-height: 22px;
              font-weight: 400;
              font-family: $decor-font;
              font-style: italic;
              display: inline-block;
              margin: 0 !important;
            }
            .date{
              font-size: 12px;
              line-height: 22px;
              font-weight: 400;
              font-family: $decor-font;
              font-style: italic;
              display: inline-block;
              margin: 0 !important;
              margin-left: 5px !important;
            }
          }
        }
      }
    }
  }
}