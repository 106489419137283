// ----------------------------------------------------------------------
//colors
// ----------------------------------------------------------------------
$color-primary:#bb9356 ;
$color-primary-dark: #976b24;
$color-gray: #f6f9fc;
$color-gray-dark: #888b90;
$color-dark: #3a3d43;
$color-black: #111111;
$color-light:#ffffff;
$color-font:#555555;
$color-green:#25b15f;
$color-red: #ff1000;
$color-orange:#f49b26;


// ----------------------------------------------------------------------
//fonts
// ----------------------------------------------------------------------
$heading-font: 'Oswald', sans-serif;
$body-font: 'Open Sans',sans-serif;
$decor-font: 'Merriweather', serif;
