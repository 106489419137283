.post-content{
  background-color: $color-light;
  margin-top: 15px;
  .detail-top{
    position: relative;
    overflow: hidden;
  }
  .content-detail{
    padding: 30px;
  }
  p {
    margin: 0 0 25px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}


.post-tags-social{
  position: relative;
  padding: 0 20px 30px 20px;
  .tags{
    ul{
      padding: 0;
      margin: 0;
      list-style: none;
      li{
        list-style: none;
        float: left;
        a{
          padding: 5px 12px;
          background-color: $color-gray;
          margin: 7px 5px;
          display: inline-block;
          text-transform: capitalize;
          font-family: $decor-font;
          font-size: 13px;
          font-style: italic;
          @include single-transition(all,ease-in-out,0.4s);
          box-shadow: 5px 5px 0 0 rgba($color-gray-dark,0.2);
          &:hover{
            background-color: $color-primary;
            color: #ffffff;
            box-shadow: 0 0 0 0 rgba($color-gray-dark,0.2);
            @include single-transition(all,ease-in-out,0.4s);
          }
        }
      }
    }
  }
  .share-links {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      float: right;
      a{
        font-weight: 500;
        display: inline-block;
        position: relative;
        padding: 6px 13px;
        margin: 10px 5px 0;
        border-radius: 0;
        background: $color-gray;
        color: $color-primary;
        text-transform: capitalize;
        font-family: $decor-font;
        font-size: 13px;
        font-style: italic;
        span {
          margin-right: 10px;
        }
        &.facebook{
          background: #4267b2;
          color: #ffffff;
          @include single-transition(all,ease-in-out,0.4s);
          box-shadow: 5px 5px 0 0 rgba(#4267b2,0.2);
          &:hover{
            opacity: 0.8;
            @include single-transition(all,ease-in-out,0.4s);
            box-shadow: 0 0 0 0 rgba(#4267b2,0.2);


          }
        }
        &.twitter{
          background: #1da1f2;
          color: #ffffff;
          @include single-transition(all,ease-in-out,0.4s);
          box-shadow: 5px 5px 0 0 rgba(#1da1f2,0.2);
          &:hover{
            opacity: 0.8;
            @include single-transition(all,ease-in-out,0.4s);
            box-shadow: 0 0 0 0 rgba(#1da1f2,0.2);

          }
        }
      }
      @include tablet{
        float: left;
      }
    }
  }
}

.content-container {
  background-color: $color-light;
  padding: 30px;
  margin: 30px 0;
}
.post-directions{
  position: relative;
  &:before {
    background: $color-gray;
    content: "";
    height: 100%;
    width: 1px;
    margin-left: -0.5px;
    position: absolute;
    left: 50%;
    display: table-cell;
    vertical-align: top;
    top: 0;
    bottom: 0;
  }
  @include tablet{
    &:before{
      content: "";
    }
  }
}
.post-paginations{
  display: inline-block;
  .post-pagi {
    float: left;
    padding-left: 40px;
    width: 50%;
    a{
      display: inline-block;
      span {
        text-transform: capitalize;
        font-family: $decor-font;
        font-size: 11px;
        font-style: italic;
        @include single-transition(all,ease-in-out,0.4s);
        color: $color-primary !important;
      }
      h5{
        font-size: 16px;
        line-height: 1.5;
        @include single-transition(all,ease-in-out,0.4s);
      }
      &:hover{
        h5{
         color: $color-primary;
          @include single-transition(all,ease-in-out,0.4s);
        }
      }
    }
    @include tablet{
      width: 100%;
      padding: 0 !important;
      margin: 15px 0;
    }

  }
  .post-pagi.prev {
    padding-right: 40px;
    padding-left: 0;
  }
}

.comment-reply-title{
  font-size: 20px;
  letter-spacing: .5px;
  line-height: 1.22727em;
  padding: 0;
  position: relative;
  margin: 0 0 30px;
  color: $color-dark;
}
.comment-form{
  label{
    padding: 0 0 5px 0;
    font-weight: 500;
    color: $color-dark;
    text-transform: capitalize;
    font-family: $heading-font;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .form-control{
    margin: 0;
  }
}


.box-comment{
  .comments-title{
    font-size: 20px;
    letter-spacing: .5px;
    line-height: 1.22727em;
    padding: 0;
    position: relative;
    margin: 0 0 30px;
    color: $color-dark;
  }
  .comment-list{
    padding: 0;
    margin: 0;
    list-style: none;
    .comment {
      border-bottom: 1px solid $color-gray;
      padding: 0 0 25px;
      margin: 0 0 25px;
      box-sizing: border-box;
      .avatar{
        float: left;
        width: 70px;
        height: 70px;
        overflow: hidden;
        margin-right: 20px;
        box-shadow: 5px 5px 0 $color-primary;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        @include tablet{
          width: 50px;
          height: 50px;
        }
      }
      .comment-box {
        overflow: hidden;
      }
      .comment-author {
        margin: 0 0 5px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        font-family: $decor-font;
        font-size: 13px;
        font-style: italic;
        span.space {
          font-size: 12px;
          margin: 0 10px;
          color: $color-gray-dark;
        }
      }
      .name-comment {
        margin: 0 0 5px;
        font-family: $heading-font;
        color: $color-dark;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 18px;
      }
      .comment-text p:last-child{
        margin-bottom: 0;
      }
      &:last-child{
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}