.header{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  min-height: 80px;
  padding: 0;
  height: auto;
  border-bottom: 1px solid rgba($color-light,0.2);
  z-index:999;
  @include single-transition(all,ease-in-out,0.4s);
  //logo
  .logo{
    font-size: 28px;
    font-weight: 500;
    color: $color-dark;
    max-height: 45px;
    padding: 0;
    line-height: 1;
    margin: 0;
    @include single-transition(all,ease-in-out,0.4s);
    .sticky-logo{
      display: none;
    }
    img{
      overflow: hidden;
      max-width: 100%;
      max-height: 40px;
    }
  }

  //menu
  .menu{
    li{
      margin: 0;
    }
    .nav-link {
      display: block;
      color:$color-light;
      font-size: 15px;
      @include single-transition(all,ease-in-out,0.4s);
      padding-left: 15px;
      padding-right: 15px;
      outline: none;
      padding-top: 30px;
      padding-bottom: 30px;
      font-family: $heading-font;
      position: relative;
      line-height: 22px;
      font-weight: 500;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      &:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 15px;
        background-color: transparent;
        width: 0;
        height: 2px;
        @include single-transition(all,ease-in-out,0.4s);
      }
      &:hover,&.active{
        color:$color-gray;
        @include single-transition(all,ease-in-out,0.4s);
        &:before{
          background-color: $color-primary;
          width: 20px;
        }
      }
      &.has_sub_menu{
        padding-right: 20px;
        &:after{
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          position: absolute;
          right: 7px;
          font-size: 10px;
          content: "\f078";
        }
      }
    }
    @include tablet{
      margin-top: 10px;
    }
  }

  //sub menu in header
  a.has_sub_menuu:after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: "\f078";
    position: absolute;
    right: 0;
    display: inline-block;
    font-size: 16px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    top: 5px;
  }
  ul.sub_menu {
    background-color: $color-light;
    border-radius: 0;
    position: absolute;
    padding: 0;
    min-width: 220px;
    width: auto;
    height: auto;
    margin: 1px 0 0 0;
    opacity: 1;
    display: none;
    color: $color-dark;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.08);
    @include single-transition(all,ease-in-out , 0.4s);
    li {
      display: block;
      padding: 0;
      margin: 0;
      a.nav-link {
        padding: 12px 15px !important;
        display: block;
        margin-left: 0;
        word-break: break-word;
        color: $color-dark;
        border-bottom: solid 1px $color-gray;
        overflow: hidden;
        border-radius: 0;
        &:hover{
          color: $color-primary;
        }
        &:before {
          content: none;
        }
      }
    }
  }
  a.nav-link.active_menu.has_sub_menu:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
  }
  .sub_menu.depth-1{
    right: 0;
    left: 100%;
    top: 0;
  }

  //header btns
  .header-btns{
    position: relative;
    margin-left: 15px;
    a{
      display: block;
      color:$color-light;
      font-size: 14px;
      font-weight: 500;
      @include single-transition(all,ease-in-out,0.4s);
      line-height: 20px;
      padding-left: 15px;
      padding-right: 15px;
      outline: none;
      padding-top: 30px;
      padding-bottom: 30px;
      text-transform: capitalize;
      font-family: $heading-font;
      border-left: solid 1px rgba($color-light,0.2);
      &:hover{
        @include single-transition(all,ease-in-out,0.4s);
        color: $color-primary;
      }
      @include tablet{
        border: 0;
        padding: 15px 10px 0px;
      }
    }
  }
  &.sticky{
    background-color: #fff;
    min-height: 70px;
    height: auto;
    @include box-shadow(5px ,5px ,15px , 0 ,rgba($color-black,0.06));
    top: 0;
    .sticky-logo {
      display: block;
      @include single-transition(all,ease-in-out,0.4s);

    }
    .main-logo{
      display: none;
      @include single-transition(all,ease-in-out,0.4s);

    }
    .menu{
      .nav-link{
        color: $color-dark;
        &:hover,&.active{
        color: $color-primary;
      }
      }
    }
    ul.sub_menu {
      color: $color-dark;
      li {
        a.nav-link {
          color: $color-dark;
          &:hover{
            color: $color-primary;
          }
        }
      }
    }
    .header-btns{
      a{
        color: $color-dark;
      }
    }
    a.nav-link{
      padding-top: 25px;
      padding-bottom: 25px;
    }
    .header-btns{
      a{
        color: $color-dark;
        padding-top: 20px;
        padding-bottom: 20px;
        border-left: solid 1px $color-gray;
      }
    }
  }
}
.header_menu_btn {
  padding: 21px 25px;
  font-size: 22px;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 !important;
  box-shadow: 0 0 0 !important;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
  background-color: transparent;
  border: 0;
  display: inline-block;
  text-align: center;
  margin: 0;
}
.header_menu_btn .navbar-toggler-icon {
  width: 20px;
  height: 3px;
  display: block;
  background-color: #080708;
  margin-bottom: 9px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
}
.header_menu_btn .navbar-toggler-icon:before {
  top: -8px;
  background-color: #333333;
  width: 13px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}
.header_menu_btn .navbar-toggler-icon:after {
  top: 8px;
  background-color: #333333;
  width: 30px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}
@media (min-width: 992px){
  .header li:hover > ul {
    display: block;
    visibility: visible;
    animation: menuEffect 0.4s both;
    @include single-transition(all,ease-in-out , 0.4s);
  }}
@media (max-width: 992px){
  .header{
    background-color: #FFFFFF;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.04);
    width: 100%;
    position: fixed;
    top: 0;
    margin-top: 0;
    padding: 0 15px;
    .btn{
      color: initial;
    }
    .btn--search{
      color: $color-dark;
      @include single-transition(all,ease-in-out,0.4s);
    }
    .logo{
      color: $color-dark;
      @include single-transition(all,ease-in-out,0.4s);
      .sticky-logo{
        display: block;
      }
      .main-logo{
        display: none;
      }
    }
    .menu-header{
      margin-top: 15px;
    }
    .menu .nav-link{
      margin: 0;
      padding: 13px 0 !important;
      line-height: 1.7;
      color: $color-dark;
      @include single-transition(all,ease-in-out,0.4s);
      &:hover,&.active{
        color: $color-primary;
        @include single-transition(all,ease-in-out,0.4s);
      }
      &:before{
        content: none !important;
      }
    }
    .header-btns{
      a{
        color: $color-dark;
        padding-top: 20px;
        padding-bottom: 20px;
        border-left: 0;
      }
    }
  }

  .header .container{
    padding: 10px 0;
  }


  .header li ul.multi_col li {
    display: block;
    width: 100%;
  }

  .header li ul.sub_menu {
    position: static;
    visibility: visible;
    width: 100%;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
  }
  .header a.has_sub_menu.nav-link:after {
    margin-right: 15px;
    top: 12px;
  }
}


.search_popup{
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  background-color:rgba($color-black,0.9);
  top: 0;
  bottom: 0;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  @include single-transition(all,ease-in-out,0.4s);
  .search-close{
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 50px;
    color: #ffffff;
    font-size: 60px;
  }
  .search-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .fullscreen-searchform{
      width: 100%;
      max-width: 500px;
      position: relative;
      .search-box{
        @include placeholder-color($color-font);
        color: $color-light;
        background-color: transparent;
        border: 0;
        border-bottom: solid 2px $color-dark;
        padding: 10px;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        display: block;
        font-size: 18px;
        outline: 0 !important;
      }
      i{
        right: 0;
        top: 0;
        position: absolute;
        color: $color-dark;
        font-size: 25px;
      }
    }
  }
  &.active{
    @include single-transition(all,ease-in-out,0.4s);
    opacity: 1;
    visibility: visible;
  }
}


@-webkit-keyframes menuEffect{
  0% {
    opacity: 0;
    top: calc(100% + 20px);
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}
@keyframes menuEffect{
  0% {
    opacity: 0;
    top: calc(100% + 20px);
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}