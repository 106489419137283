/*
* Resto -Responsive Template For Restaurant
* Build Date: dec 2019
* Author: PharaohLab
* Copyright (C) 2019 PharaohLab
*/

/* ------------------------------------- */
/* TABLE OF CONTENTS
/* -------------------------------------
*.@Import
1.normalize
2.global
3.typography
------------------------------------- */
@import url('https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Open+Sans|Oswald:500,600,700&display=swap');
@import "base/_vars";
@import "base/_mixins";
@import "base/_reset";
@import "base/_general";
/* ------------------------------------- */
/* *. typography .......................... */
/* ------------------------------------- */
@import "base/_typography";
/* ------------------------------------- */
/* *. Header .......................... */
/* ------------------------------------- */
@import "layout/_header";
/* ------------------------------------- */
/* *. Footer .......................... */
/* ------------------------------------- */
@import "layout/_footer";
/* ------------------------------------- */
/* *. forms .......................... */
/* ------------------------------------- */
@import "components/_forms";
@import "components/_nice_selec";
/* ------------------------------------- */
/* *. buttons .......................... */
/* ------------------------------------- */
@import "components/_buttons";
/* ------------------------------------- */
/* *. cover .......................... */
/* ------------------------------------- */
@import "components/_cover";
///* ------------------------------------- */
/* *. Icon Box .......................... */
/* ------------------------------------- */
@import "components/_icon_boxes";
/* ------------------------------------- */
/* *. call to action .......................... */
/* ------------------------------------- */
@import "components/_call_to_action";
/* ------------------------------------- */
/* *. Section Title .......................... */
/* ------------------------------------- */
@import "components/_section_title";
///* ------------------------------------- */
///* *. Section blog.......................... */
///* ------------------------------------- */
@import "layout/_blog";
// widget section for the sidebar
@import "layout/_widget";
//single post
@import "layout/_single-post";
/*
//------------------------------------- */
/* *. Section tabs .......................... */
/* ------------------------------------- */
@import "components/_tabs";
//------------------------------------- */
/* *. Section Menus .......................... */
/* ------------------------------------- */
@import "components/_menus";
/* ------------------------------------- */
/* *. Section testimonial .......................... */
/* ------------------------------------- */
@import "components/_testimonial";
/* ------------------------------------- */
/* *. Section Team .......................... */
/* ------------------------------------- */
@import "components/_team";
/* ------------------------------------- */
/* *. Section img Box .......................... */
/* ------------------------------------- */
@import "components/img_box";
/* ------------------------------------- */
/* *. Portfolio .......................... */
/* ------------------------------------- */
@import "components/_portfolio";
