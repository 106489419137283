html {
  height: 100%;
  margin: 0!important;
  @include single-transition(all,ease-in-out,0.3s);
  overflow-y: scroll!important
}

body {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  overflow-x: hidden!important;
  font-family: $body-font;
  color: $color-gray-dark;
  background: $color-gray;
}

h1,h2,h3,h4,h5,h6 {
  font-family: $heading-font;
  color: $color-dark;
  font-weight: 700;
  text-transform: uppercase
}

h1 {
  font-size: 60px;
  letter-spacing: 1px;
  line-height: 1.11667em;
  @include tablet{
    font-size: 38px;
    line-height: 1.3667em;
  }
}

h2 {
  font-size: 35px;
  letter-spacing: 1px;
  line-height: 1.14286em;
  @include tablet{
    font-size: 28px;
    line-height: 1.4286em;
  }
}

h3 {
  font-size: 20px;
  letter-spacing: .5px;
  line-height: 1.22727em;
}

h4{
  font-size: 18px;
  line-height: 1.31579em;
}

h5 {
  font-size: 14px;
  line-height: 1.28571em
}

h6 {
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 1.35714em
}


input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

p {
  margin: 0 0 12.5px
}

.lead {
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4
}

small,
.small {
  font-size: 87%
}

h1 a,h2 a,h3 a,h4 a,h5 a,h6 a {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit
}

h1 a:hover,h2 a:hover,h3 a:hover,h4 a:hover,h5 a:hover,h6 a:hover {
  color: $color-primary;
}

a,p a {
  color: $color-primary;
  text-decoration: none;
  cursor: pointer;
 @include single-transition(all,ease-in-out,0.4s);
}

p {
  margin: 0 0 25px
}




//lists and html elements 

ol,ul {
  list-style-position: inside;
  margin: 0;
}

ol ol,ol ul,ul ol,ul ul {
  margin: 0;
  padding: 0 0 0 15px
}

pre {
  background-color: $color-light;
  padding: 15px;
  max-width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 15px 0
}

dt {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px
}

dd {
  margin-bottom: 15px
}

sub,sup {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  top: .5ex
}

sup {
  bottom: .5ex
}

.wp-caption-text {
  margin: 0;
  font-style: italic
}

table {
  width: 100%
}

table tbody tr,table thead tr {
  border: 1px solid #efefef
}

table tbody th {
  border-right: 1px solid #efefef
}

blockquote {
  color: $color-dark;
  font-size: 18px;
  padding: 0 28px;
  border-left: 2px solid $color-primary;
  font-style: italic;
  margin-bottom: 20px;
  font-family: $decor-font;
  p{
    font-family: $decor-font;
  }
  @include tablet{
    font-size: 16px;
    line-height: 1.8;
    padding: 0 9px;
  }
}

blockquote cite {
  font-style: normal;
  font-weight: 500
}

::selection {
  background: $color-primary;
  color: $color-light
}

::-moz-selection {
  background: $color-primary;
  color: $color-light
}

.bypostauthor,.gallery-caption,.sticky,.wp-caption,.wp-caption-text {
  opacity: 1
}

.alignleft {
  float: left;
  margin: 0 20px 20px 0
}

.alignright {
  float: right;
  margin: 0 0 20px 20px
}

.aligncenter {
  display: block;
  margin: 10px auto
}

.alignwide {
  margin-left: auto;
  margin-right: auto;
  clear: both
}

@media only screen and (max-width: 1024px) {
  .alignwide {
    width:100%;
    max-width: 100%
  }
}

.alignfull {
  position: relative;
  left: -1rem;
  width: calc(100% + (2 * 1rem));
  max-width: calc(100% + (2 * 1rem));
  clear: both
}

@media only screen and (max-width: 1024px) {
  .alignfull {
    left:calc(-12.5% - 75px);
    width: calc(125% + 150px);
    max-width: calc(125% + 150px);
    margin-top: calc(2 * 1rem);
    margin-bottom: calc(2 * 1rem)
  }
}

.text-align-right {
  text-align: right
}

.text-align-left {
  text-align: left
}

.text-align-center {
  text-align: center
}