.blog-post-one{
  position: relative;
  @include single-transition(all,ease-in-out,0.4s);
  display: inline-block;
  overflow: hidden;
  border-radius: 0;
  margin: 15px 0;
  background-color: $color-light;
  figure{
    max-height: 250px;
    overflow: hidden;
    img{
      min-height: 250px;
      width: 100%;
      overflow: hidden;
    }
  }
  .info-post{
    position: relative;
    padding: 30px 15px 30px;
    float: left;
    width: 100%;
    clear: both;
    .cat-name {
      position: absolute;
      color: $color-light;
      left: 15px;
      top: -15px;
      background-color: $color-primary;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      z-index: 4;
      overflow: hidden;
      border-radius: 0;
      padding: 3px 10px;
      font-family:$heading-font;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .post-title {
      margin-top: 10px;
      margin-bottom: 10px;
      font-family: $heading-font;
      clear: both;
      a{
        color: inherit;
      }
    }
    p {
      margin: 15px 0;
      line-height: 25px;
      letter-spacing: 0;
      width: 100%;
    }
    .footer-info-post {
      display: -webkit-flex;
      display: flex;
      justify-content: space-between;
    }
    .meta-post {
      padding: 0;
      margin: 0;
      li {
        position: relative;
        display: inline-block;
        font-weight: 500;
        text-transform: uppercase;
        padding-right: 10px;
        margin: 0;
        color: $color-primary;
        font-family:$decor-font;
        font-size: 13px;
        font-style: italic;
        line-height: 14px;
        margin-bottom: 10px;
        i{
          padding-right: 5px;
          font-size: 12px;
          position: relative;
          color: $color-primary;
        }
      }
    }
    .arrow-btn {
      font-size: 12px;
      position: relative;
      display: block;
      z-index: 2;
      font-weight: 500;
      overflow: hidden;
      min-width: 27px;
      top: 2px;
    }
  }
  &:hover{
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.06);
    @include single-transition(all,ease-in-out,0.4s);
  }
}
.blog-post-two{
  background-color: $color-light;
  position: relative;
  @include single-transition(all,ease-in-out,0.4s);
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: 5px;
  margin: 30px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.03);
  .blog-side-box{
    overflow: hidden;
    width: 50%;
    float: left;
    display: flex;
    position: relative;
  }
  .wp-post-image{
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .blog-side-box-inner{
    display: flex;
    position: relative;
    padding: 20px 15px 30px;
    align-items: center;
  }
  .info-post{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 75px 50px;
    .cat-name {
      position: relative;
      color: $color-light;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      z-index: 4;
      overflow: hidden;
      border-radius: 4px;
      background-color: $color-primary;
      padding: 3px 10px;
      max-width: 100px;
      margin-bottom: 12px;
      text-align: center;
      display: inline-block;
      margin-right: auto;
      margin-left: 0;
    }
    .post-title {
      font-size: 18px;
      font-weight: 600;
      clear: both;
      line-height: 28px;
      margin-top: 6px;
      margin-bottom: 10px;
      font-family: $heading-font;
      a{
        color: inherit;
      }
    }
    p {
      margin: 0 0 10px;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0;
      width: 100%;
    }
    .footer-info-post {
      display: -webkit-flex;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    .meta-post {
      padding: 0;
      margin: 0;
      li {
        position: relative;
        display: inline-block;
        font-size: 12px;
        line-height: 24px;
        font-weight: 500;
        text-transform: uppercase;
        padding-right: 10px;
        margin: 0;
        i{
          padding-right: 5px;
          font-size: 12px;
          position: relative;
          color: $color-primary;
        }
      }
    }
    .arrow-btn {
      font-size: 12px;
      position: relative;
      display: inline-block;
      z-index: 2;
      font-weight: 500;
      overflow: hidden;
      min-width: 27px;
      top: 10px;
    }
  }
  &:hover{
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.06);
    @include single-transition(all,ease-in-out,0.4s);
  }
}
.blog-articles article:nth-child(odd) {
  .blog-post-two{
    .side-post-content{
      text-align: right;
      .info-post{
        .cat-name {
          display: inline-block;
          margin-right: 0;
          margin-left: auto;
        }
        .footer-info-post {
          text-align: right;
        }
        .arrow-btn {
          transform: rotate(180deg);
          max-width: 50px;
          margin-right: 0;
          margin-left: auto;
        }
      }
    }
    .side-post-image{
      float: right;
      order: 2;
    }
  }
}
.blog-pagination{
  display: inline-block;
  margin: 50px auto;
  float: none;
  width: 100%;
  text-align: center;
  a,span{
    display: inline-block;
    width: 40px;
    height: 40px;
    color: $color-primary;
    line-height: 39px;
    margin-right: 7px;
    font-size: 15px;
    text-align: center;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 4px;
    @include single-transition(all,ease-in-out,0.4s);
    i{
      margin-left: 7px;
      margin-right: 7px;
    }
    &.current ,&:hover {
      color: #fff;
      background-color: $color-primary;
    }
    &.prev,&.next{
      width: 75px;
    }
  }
  @include tablet{
    margin: 20px auto 10px ;
  }
}


//sidebar

.sidebar{
  margin-bottom: 90px;
  padding: 15px 20px;
  &.sidebar-left {
    padding-left: 0;
  }
  &.sidebar-right {
    padding-right: 0;
  }
  @include tablet{
    margin-bottom: 15px;
    padding: 0 !important;
  }
}
