//inner cover
.inner-cover{
  padding: 200px 0 150px 0;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $color-primary;
  text-align: center;
  .cover-heading{
    margin: 0;
    text-transform: uppercase;
    font-style: normal;
    color: $color-light;
    font-weight: 700;
    font-family: $heading-font;
    &:before {
      content: "";
      position: absolute;
      top: -20px;
      width: 50%;
      height: 2px;
      max-width: 70px;
      background-color: $color-light;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
  .cover-subheading{
    max-width: 600px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: $color-gray;
    font-weight: 400;
    font-family:$body-font;
    font-size: 1em;
    display: inline-block;
    line-height: 1.5625em;
  }
  .breadcrumbs-path{
    background: 0 0;
    font-weight: 400;
    position: relative;
    padding: 0;
    max-width: 66%;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 20px auto 0;
    a{
      font-weight: 400;
      margin-right: 10px;
      opacity: .5;
      color: $color-gray;
      font-family: $decor-font;
      font-size: 16px;
      font-style: italic;
      @include single-transition(all,ease-in-out,0.4s);
      &:hover{
        opacity: 1;
        @include single-transition(all,ease-in-out,0.4s);
      }
      @include tablet{
        font-size: 14px;
      }
    }
    span{
      font-weight: 400;
      margin-right: 10px;
      color: $color-gray;
      font-family: $decor-font;
      font-size: 16px;
      font-style: italic;
      @include tablet{
        font-size: 14px;
      }
    }
    i{
      margin-right: 10px;
      opacity: .5;
      color: $color-gray;
      font-size: 0.93758em;
      line-height: 1.5em;
      &:last-of-type{
        opacity: 1;
      }
    }
  }
  .meta-post{
    background: 0 0;
    font-weight: 400;
    position: relative;
    max-width: 100%;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 20px auto 0;
    padding: 0;
    list-style: none;
    text-align: center;
    li{
      font-weight: 400;
      color: $color-light;
      font-family: $decor-font;
      font-size: 16px;
      font-style: italic;
      float: none;
      margin: 0 auto;
      display: inline-block;
      padding: 0 7px;
      line-height: 2.5;
      a{
        color: $color-gray;
        &:hover{
          color: $color-primary;
        }
      }
      i{
        margin-right: 3px;
      }
    }
  }
  &.bg-img{
    .cover-heading{
      &:before {
        background-color: $color-primary;
      }
    }
  }
}

//cover slider
.cover-slider{
  padding: 0;
  margin: 0;
  width: 100%;
  display: block;
  position: relative;
  list-style: none;
  min-height: 500px;
  z-index: 0;
  .owl-item {
    padding: 0 !important;
  }
  li {
    float: none;
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;
    display: block;
    position: relative;
    min-height: 650px;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    @include tablet{
      height: 70vh;
      min-height: 550px;
    }
  }
  .overlay-slider {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($color-black,0.6);
  }
  .slider_content {
    display: table;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    position: relative;
    color: #ffffff;
    .slider-content-center {
      max-width: 900px;
      margin: 0 auto;
      text-align: center;
    }
    .slider-content-left {
      max-width: 750px;
      text-align: left;
      h2 {
        &:after {
          margin-left: 0 !important;
        }
      }
    }
    .slider-content-inner {
      display: table-cell;
      vertical-align: middle;
      .icon{
        max-width: 200px;
        margin: 0 auto;
        margin-bottom: 20px;
        opacity: 0;
        color: $color-light;
        transform: translateY(20px);
        transition: all 0.4s 600ms ease-in-out;
        svg,svg *{
          fill: $color-light;
          width: 80px;
          @include tablet{
            width:50px;
          }
        }
        @include tablet{
          width:50px;
        }
      }
      .sub-title{
        color:$color-gray;
        font-family: $decor-font;
        font-style: italic;
        font-weight: 500;
        line-height: 45px;
        margin-bottom: 0;
        text-transform: capitalize;
        letter-spacing: 1px;
      }
      h2 {
        opacity: 0;
        color: $color-light;
        text-transform: uppercase;
        @include single-transition(all,ease-in-out,0.4s);
        font-size: 80px;
        line-height: 100px;
        font-weight: 700;
        font-family: $heading-font;
        letter-spacing: 2px;
        visibility: inherit;
        text-align: center;
        margin:0 auto 20px auto;
        &.style-pattern:after{
          background-image: url(../imgs/pattern.png);
          background-repeat: no-repeat;
          background-size: auto;
          background-position: center center;
          overflow: hidden;
          border: 0 !important;
          width: 80px;
          height: 30px;
          content: "";
          text-align: left;
          margin: 20px auto;
          line-height: 1;
          display: block;
        }
        &.style-pattern.light-color:after{
          background-image: url(../imgs/pattern-light.png);
        }
        &.style-pattern.dark-color:after{
          background-image: url(../imgs/pattern-dark.png);
        }
        @include tablet{
          font-size: 42px;
          line-height: 50px;
          letter-spacing: 1px;
        }
      }
      p {
        color: $color-gray;
        margin-bottom: 20px;
        margin-top: 0;
        opacity: 0;
        transform: translateY(20px);
        transition: all 0.4s 900ms ease-in-out;
        font-size: 24px;
        line-height: 38px;
        font-family: $decor-font;
        font-style: italic;
        font-weight: 500;
        text-align: center;
        border-width: 0;
        @include tablet{
          font-size: 16px;
          line-height: 24px;
        }
      }
      a {
        opacity: 0;
        transform: translateY(20px);
        transition: all  300ms ease-in-out, transform  0.4s 300ms ease-in-out, opacity 0.4s 1200ms ease-in-out, -webkit-transform  0.4s 1200ms ease-in-out;
        &:last-of-type{
          margin-left: 10px;
        }
      }

    }
  }
  .active{
    .slider-content-inner *{
      .icon{
        opacity: 1;
        transform: translateY(0px);
        transition: all 0.4s 600ms ease-in-out;
      }
      h2{
        opacity: 1;
        transform: translateY(0px);
        transition: all 0.4s 600ms ease-in-out;
      }
      p {
        opacity: 1;
        transform: translateY(0px);
        transition: all 0.4s 900ms ease-in-out;
      }
      a {
        opacity: 1;
        transform: translateY(0px);
        transition: all  300ms ease-in-out, opacity 0.4s 1200ms ease-in-out, color 0.4s  ease-in-out, border 0.4s  ease-in-out, background-color 0.4s  ease-in-out;
      }
    }
  }

  .owl-dots{
    bottom: 25px;
    text-align: center;
    position: absolute;
    width: 100%;
    z-index: 9;
    .owl-dot{
      border-radius: 0;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
      transition: all 0.3s ease-in-out, transform 0.3s ease;
      background:transparent;
      position: relative;
      display: inline-block;
      margin: 0 5px;
      height: 10px;
      width: 10px;
      span {
        transition: all 0.3s ease-in-out, transform 0.3s ease;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        outline: none;
        border-radius: 0;
        background-color: #FFF;
        cursor: pointer;
        position: absolute;
        margin: 0 auto;
      }
    }
    .owl-dot.active{
      box-shadow: 0 0 0 2px #FFF;
      border:none;
      border-radius: 0;
      background:transparent;
      span {
        transform: scale(0.4);
        -webkit-transform: scale(0.4);
        background-color:#fff;
      }
    }
  }
  .owl-nav {
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 9;
    left: 0;
    color: #FFF !important;
    font-size: 16px;
    @include tablet{
      display: none;
    }
    [class*=owl-] {
      color: #FFFFFF !important;
      font-size: 16px;
      background-color: transparent !important;
      border: solid 1px rgba($color-light,0.4) !important;
      margin: 0 10px;
      top: 45%;
      width: 50px;
      height: 50px;
      z-index: 99999;
      padding: 10px 12px !important;
      display: inline-block;
      cursor: pointer;
      border-radius: 0;
      position: absolute;
      line-height: 1;
      transition: all ease-in-out 0.4s;
      &:hover {
        background: rgba(0, 0, 0, 0.58) !important;
        border: solid 1px rgba($color-black,0.4) !important;
        -webkit-transition: all ease-in-out 0.4s;
        transition: all ease-in-out 0.4s;
      }
    }
    button.owl-prev {
      padding: 10px 12px !important;
      left: 0;
    }
    button.owl-next {
      padding: 10px 12px !important;
      right: auto;
      left: calc(100vw - 90px)
    }
  }

}

.vh120{
  height: 120vh !important;
  .cover-slider li{
    height: 120vh !important;
  }
}
//mouse-wheel
.mouse-wheel{
  border: 2px dashed $color-primary;
  height: 40px;
  width: 24px;
  display: block;
  z-index: 10;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  opacity: 0.7;
  margin: 0 auto;
  background-color: rgba(255,255,255,.5);
  border-radius: 0;
  .wheel{
    background-color: #ffffff !important;
    position: relative;
    width: 2px;
    height: 6px;
    top: 4px;
    margin-left: auto;
    margin-right: auto;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-animation-name: mouse-anim-drop;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-play-state: running;
    -webkit-animation-name: mouse-anim-drop;
    animation-name: mouse-anim-drop;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
}
@-webkit-keyframes mouse-anim-drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}
@keyframes mouse-anim-drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}



//cover section

.cover-section{
  position: relative;
}

.cover{
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  min-height: 620px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  transition: all ease-in-out 0.4s;
  text-align: center;
  .overlay{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($color-black,0.4);
  }
  .cover-shap-one{
    margin-bottom: 25px;
    content: "";
    position: absolute;
    top: -50px;
    background-color: #feffff;
    border-bottom: 0;
    width: 2px;
    height: 40px;
    border-left-width: 0;
    border-left-style: solid;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
  }
  .sub-title{
    color:$color-gray;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    line-height: 45px;
    margin-bottom: 0;
    text-transform: capitalize;
    letter-spacing: 1px;
  }
  .cover-title {
    color: #ffffff;
    font-size:62px;
    line-height: 78px;
    font-weight: 700;
    text-transform: uppercase;
    @include single-transition(all,ease-in-out,0.4s);
    font-family: $heading-font;
    letter-spacing: 2px;
    visibility: inherit;
    text-align: center;
    margin:0 auto 20px auto;
    @include tablet{
      font-size: 42px;
      line-height: 60px;
    }
  }
  .cover-desc{
    color: #f7f7f7;
    font-size: 24px;
    line-height: 38px;
    font-weight: 400;
    margin-bottom: 15px;
    @include tablet{
      font-size: 18px;
    }
  }
  .btn{
    margin-top: 25px;
    margin-left: 5px;
    margin-right: 5px;
    @include tablet{
      margin: 10px;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

// section title
