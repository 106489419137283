/* ------------------------------------- */
/* *. global .......................... */
/* ------------------------------------- */
@include placeholder-color($color-font);

.back-to-top {
  position: fixed;
  z-index: 100;
  bottom: 40px;
  padding: 14px;
  right: -50px;
  text-decoration: none;
  background-color: $color-primary;
  color: $color-light;
  font-size: 18px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  line-height: 55px;
  border-radius: 0;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  box-shadow: 5px 5px 0 0 rgba($color-primary,0.4);
  i{

    display: block;
    line-height: 1;
  }
}

.back-to-top:hover {
  background-color: $color-dark;
  color: $color-light;
  box-shadow: 0 0 0 0 rgba($color-primary,0.4);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.back-to-top.active {
  right: 25px;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.preloader {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 99999999;
  height: 100%;
  width: 100%;
  background-color: $color-black;
  .preloader-wapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100vh;
  }
}
.spinner-loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.spinner-loader div {
  position: absolute;
  border: 4px solid $color-primary;
  opacity: 1;
  border-radius: 0;
  -webkit-animation: spinner-loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: spinner-loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.spinner-loader div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

@-webkit-keyframes spinner-loader {
  0% {
    top: 35px;
    left: 35px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 70px;
    height: 70px;
    opacity: 0;
  }
}

@keyframes spinner-loader {
  0% {
    top: 35px;
    left: 35px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 70px;
    height: 70px;
    opacity: 0;
  }
}


.brand_item {
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  max-width: 225px;
}
.brand-grid{
  img{
    margin: 50px auto;
    display: block;
  }
}
.brand_item_two{
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  max-width: 225px;
  img {
    margin: 0 auto;
    opacity: 0.4;
    @include single-transition(all,ease-in-out,0.4s);
  }
  &:hover{
    img{
      @include single-transition(all,ease-in-out,0.4s);
      opacity: 1;
    }
  }
}
.brand_item img {
  margin: 0 auto;
}
.owl-carousel .owl-item img{
  width: auto;
}
.owl-carousel{
  direction: ltr !important;
  .item , .owl-item{
    padding: 10px;
  }

}

//alerts box
.alert {
  border-radius: 0;
  font-size: 16px;
  padding: 25px 25px 25px 100px;
  border: 0;
  color: #fff;
  position: relative;
  margin-bottom: 25px;
}
.alert p {
  margin: 0;
}
.alert .alert-icon {
  background-color: rgba(0, 0, 0, 0.075);
  position: absolute;
  width: 75px;
  text-align: center;
  left: 0;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.alert .alert-icon i {
  font-size: 20px;
}
.alert.alert-dismissible {
  padding-right: 35px;
}
.alert .close {
  position: absolute;
  right: 5px;
  top: 50%;
  height: 15px;
  line-height: 26px;
  margin-top: -25px;
  opacity: 1;
  font-size: 12px;
  outline: none;
}
.alert .close i {
  text-shadow: none;
  font-size: 10px;
  font-weight: bold;
}
.alert.alert-warning {
  background-color: #ffbb44;
}
.alert.alert-danger {
  background-color: #f42841;
}
.alert.alert-success {
  background-color: #0ee092;
}
.alert.alert-info {
  background-color: #35c3fa;
}


/* -------- "margin helpers" and "padding helpers"  --------- */
$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50,55,60,65,70, 75,80,85,90,95, 100);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts{
  @each $side in $sides{
    .m#{str-slice($side, 0, 1)}#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}


//colors

.color-light{
  color: $color-light !important;
}
.color-gray{
  color: $color-gray !important;
}
.color-dark{
  color: $color-dark !important;
}
.color-black{
  color: $color-black !important;
}
.color-text{
  color: $color-font !important;
}
.color-primary{
  color: $color-primary !important;
}


.fill-light{
  fill: $color-light !important;
  svg,svg *{
    fill: $color-light !important;
  }
}
.fill-gray{
  fill: $color-gray !important;
  svg,svg *{
    fill: $color-gray !important;
  }
}
.fill-dark{
  fill: $color-dark !important;
  svg,svg *{
    fill: $color-dark !important;
  }
}
.fill-black{
  fill: $color-black !important;
  svg,svg *{
    fill: $color-black !important;
  }
}
.fill-primary{
  fill: $color-primary!important;
  svg,svg *{
    fill: $color-primary!important;
  }
}

//background colors
.bg-light{
  background-color: $color-light !important;
}
.bg-gray{
  background-color: $color-gray !important;
}
.bg-dark{
  background-color: $color-dark !important;
}
.bg-black{
  background-color: $color-black !important;
}
.bg-text{
  background-color: $color-font !important;
}
.bg-primary{
  background-color: $color-primary !important;
}

.bg-gradient{
  @include linear-gradient($color-primary, $color-primary-dark);
}
//background shapes

.shape-top{
  position: absolute !important;
  z-index: 4 !important;
  width: 100%;
  top: 0 !important;
  left: 0;
  right: 0;
  bottom: auto !important;
}
.shape-style-one-flip{
  transform: rotate(180deg);
  position: absolute !important;
  z-index: 4 !important;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}
.bg-dark .shape-style-one{
  .svg,.waves{
    fill: $color-dark;
  }
}
.bg-gray .shape-style-one{
  .svg,.waves{
    fill: $color-gray;
  }
}
.bg-light .shape-style-one{
  .svg,.waves{
    fill: $color-light;
  }
}

.bg-dark .shape-style-one-flip{
  .svg,.waves{
    fill: $color-dark;
  }
}
.bg-gray .shape-style-one-flip{
  .svg,.waves{
    fill: $color-gray;
  }
}
.bg-light .shape-style-one-flip{
  .svg,.waves{
    fill: $color-light;
  }
}
//background images

.bg-img{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  .overlay{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    &.overlay-dark{
      background-color: rgba($color-black,0.7);
    }
    &.overlay-light{
      background-color: rgba($color-light,0.8);
    }
  }
}
.bg-img-content{
  background-size: contain;
  background-repeat: repeat;
  background-position: center center;
  position: relative;
  .overlay{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    &.overlay-dark{
      background-color: rgba($color-black,0.7);
    }
    &.overlay-light{
      background-color: rgba($color-light,0.8);
    }
  }
}
.overlay-gradient{
  opacity: 0.9;
  @include linear-gradient($color-primary,$color-primary-dark);
}
//container box

.mt-100{
  margin-top: -100px !important;
}
.mt-150{
  margin-top: -150px !important;
}
.mt-200{
  margin-top: -200px !important;
}
.mb-150{
  margin-bottom: -150px !important;
}
.container-box{
  z-index: 9;
  position: relative;
  padding:50px;
  border-radius: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
}
.light-box{
  z-index: 9;
  position: relative;
  padding: 0;
  border-radius: 0;
  overflow: hidden;
}
.no-border{
  border:0 !important;
}

.font-restaurant{
  font-family: $decor-font;
  font-style: italic;
  font-weight: 500;
}

.sponser_img_list{
  position: relative;
  margin:0 auto ;
  img{
    padding: 10px;
    max-width: 120px;
  }
}

@include tablet{
  .mb-tablet-50{
    margin-bottom: 50px;
  }
}