.plate-box {
  padding: 15px;
  text-align: center;
  margin: 15px 0;
  .plate-img {
    background-position: center center;
    background-size: cover;
    width: 300px;
    min-height: 300px;
    border-radius: 100%;
  }
  .title {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .price {
    line-height: 1.5;
    color: $color-primary;
    font-family: $decor-font;
    font-style: italic;
    font-weight: 500;
    font-size: 34px;
  }
  .desc {
    margin-top: 8px;
    font-size: 16px;
  }
}
.special-plate {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
  padding: 60px;
  color:$color-light;
  position: relative;
  .overlay {
    background-color: rgba($color-black,0.7);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .special-plate-data {
    position: relative;
  }
  .title {
    color: $color-light;
    font-family: $decor-font;
    font-style: italic;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 38px;
    @include tablet{
      font-size: 30px;
    }
  }
  .desc {
    font-size: 18px;
    margin: 0;
  }
  .price {
    font-family: $decor-font;
    font-style: italic;
    font-weight: 500;
    font-size: 59px;
    margin: 20px 0 30px;
    color: $color-light;
    line-height: 1.1;
    @include tablet{
      margin: 15px 0 20px;
    }
  }
  @include tablet{
    padding: 26px;
    color: #ffffff;
    position: relative;
    margin-bottom: 25px;
    min-height: 350px;
  }
}