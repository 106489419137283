.footer{
  position: relative;
  background: $color-black;
  border-top: solid 1px $color-black;
  padding: 100px 0;
  width: 100%;
  .footer-copyright{
    .logo{
      max-height: 35px;
      display: inline-block;
      margin-bottom: 10px;
      img{
        max-height: 35px;
      }
    }
    p{
      font-size: 13px;
      color: $color-font;
      margin-bottom: 0;
      font-family: $decor-font;
      FONT-STYLE: italic;
      text-transform: capitalize;
    }
    @include tablet{
      text-align: center;
      margin-bottom: 15px;
    }
  }

  .footer-mail-info {
    .title {
      color: $color-light;
      font-size: 25px;
      font-weight: 700;
      margin: 0 0 5px;
    }
    p{
      font-size: 13px;
      color: $color-font;
      line-height: 1em;
      margin-bottom: 0;
      font-family: $decor-font;
      FONT-STYLE: italic;
      text-transform: capitalize;
    }
    @include tablet{
      text-align: center;
      margin-bottom: 5px;
    }
  }
}

.footer-social{
  margin-bottom: 0;
  text-align: center;
  a{
    background: $color-dark;
    border: solid 2px $color-dark;
    padding: 6px 0;
    border-radius: 0;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    font-size: 16px;
    color: $color-light;
    text-align: center;
    box-shadow: 5px 5px 0 0 rgba($color-dark,0.4);
    &:hover{
      background-color: $color-primary;
      border: solid 2px $color-primary;
      color: $color-light;
      box-shadow: 0 0 0 0 rgba($color-dark,0.4);
    }
  }
  @include tablet{
    margin-bottom: 25px;
  }
}

.social{
  margin-bottom: 0;
  text-align: center;
  a{
    background: $color-dark;
    border: solid 2px $color-dark;
    padding: 6px 0;
    border-radius: 0;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    font-size: 16px;
    color: $color-light;
    text-align: center;
    box-shadow: 5px 5px 0 0 rgba($color-dark,0.4);
    &:hover{
      background-color: $color-primary;
      border: solid 2px $color-primary;
      color: $color-light;
      box-shadow: 0 0 0 0 rgba($color-dark,0.4);
    }
  }
  @include tablet{
    margin-bottom: 25px;
  }
}